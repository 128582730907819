import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { AxisOptions, Chart } from "react-charts";
import { usePrices } from "../hooks/usePrices";

interface PriceGraphProps {
  model: SelectObject | null;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

type PricesDatum = {
  year: string,
  amount: number
}

export const PricesChart = ({ model, setLoading, }: PriceGraphProps) => {
  const { prices, isPricesLoading } = usePrices({ modelId: model?.id});

  useEffect(() => {
    setLoading(isPricesLoading);
  }, [isPricesLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = useMemo(() => {
    return [
      {
        label: 'R$',
        data: (prices && [...prices].sort((a, b) =>  (a.modelYear - b.modelYear) * -1).map(p => {
          return {
            year: p.modelYear === 32000 ? "Zero KM" : `${p.modelYear}`,
            amount: p.amount,
          };
        })) || [],
      },
    ]
  },[prices]);

  const primaryAxis = useMemo((): AxisOptions<PricesDatum> => ({
    getValue: datum => datum.year,
  }), []);

  const secondaryAxes = useMemo((): AxisOptions<PricesDatum>[] => [{
    getValue: datum => datum.amount,
    elementType: 'area',
    hardMin: prices && prices.length > 0 ? Math.floor(prices.reduce((prev, curr) => prev.amount < curr.amount ? prev : curr).amount / 10000) * 10000 : 0,
  }], [prices]);

  return (
    <>
      {prices && prices.length > 0 && (
        <div>
          <h2 className="title">Desvalorização</h2>
          <p className="title">{model?.label}</p>
          <div style={ {height: `${prices.length * 35}px`} }>
            <Chart
              options={{
                data,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
