import useSWR from "swr";

type RefResponse = {
  id: number;
  label: string;
}

export const useRefs = () => {
  const { data, isLoading } = useSWR<RefResponse[]>(`/refs`);

  return {
    refs: data,
    isRefsLoading: isLoading
  }
};
