import useSWR from "swr";

type PriceRequest = {
  modelId?: number;
}

export const usePrices = ({modelId}: PriceRequest) => {
  const params = new URLSearchParams({ modelId: `${modelId}` });
  const key = `/prices?${params}`;
  const { data, isLoading } = useSWR<Price[]>(key);

  return {
    prices: data,
    isPricesLoading: isLoading
  }
};
