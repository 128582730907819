import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Autocomplete, TextField } from "@mui/material";
import { useModels } from "../hooks";

interface ModelsProps {
  brandId: number;
  selectedModel: SelectObject | null;
  setSelectedModel: Dispatch<SetStateAction<SelectObject | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const Models = ({brandId, selectedModel, setSelectedModel, loading, setLoading}: ModelsProps) => {
  const { models, isModelsLoading } = useModels({brandId})

  useEffect(() => {
    setLoading(isModelsLoading);
  }, [isModelsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="componentMainDiv">
      <div className="selectDiv">
        <Autocomplete
          id="models"
          autoHighlight={true}
          options={models || []}
          value={selectedModel}
          onChange={(_event: React.SyntheticEvent, newValue: SelectObject | null) => {
            setSelectedModel(newValue);
          }}
          loading={loading}
          loadingText="Carregando..."
          sx={{width: "350px"}}
          renderInput={(params) => <TextField {...params} label="Modelo"/>}
        />
      </div>
    </div>
  );
};
