import useSWR from "swr";

type ModelRequest = {
  brandId: number;
}

export const useModels = ({brandId}: ModelRequest) => {
  const params = new URLSearchParams({ brandId: `${brandId}` });
  const key = `/models?${params}`;
  const { data, isLoading } = useSWR<SelectObject[]>(key);

  return {
    models: data,
    isModelsLoading: isLoading
  }
};
