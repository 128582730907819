export {};

declare global {

  interface SelectObject {
    id: number;
    label: string;
  }

  interface Price {
    modelYear: number;
    amount: number;
  }
}

export const cars: number = 1;
export const bikes: number = 2;
export const trucks: number = 3;
