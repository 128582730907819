import useSWR from "swr";

type BrandRequest = {
  refId: number;
  vehicleType: number;
}

export const useBrands = ({refId, vehicleType}: BrandRequest) => {
  const params = new URLSearchParams({ refId: `${refId}`, vehicleTypeId: `${vehicleType}` });
  const key = `/brands?${params}`;
  const { data, isLoading } = useSWR<SelectObject[]>(key);

  return {
    brands: data,
    isBrandsLoading: isLoading
  }
};
