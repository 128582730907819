import React, { Dispatch, SetStateAction } from 'react';
import Tabs from "@mui/material/Tabs";
import { Tab, Tooltip } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

interface VehicleTypeProps {
  selectedTab: number | null;
  setSelectedTab: Dispatch<SetStateAction<number>>;
}

export const VehicleType = ({selectedTab, setSelectedTab}: VehicleTypeProps) => {

  const changeSelectedTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
      <Tabs value={selectedTab}
            onChange={changeSelectedTab}
            variant={"fullWidth"}
            centered
            aria-label="Selecione o tipo de Veículo"
      >
        <Tooltip title="Carros" placement="top">
          <Tab value={0} icon={<DirectionsCarIcon/>} aria-label="Carros"/>
        </Tooltip>

        <Tooltip title="Motos" placement="top">
          <Tab value={1} icon={<TwoWheelerIcon/>} aria-label="Motos"/>
        </Tooltip>

        <Tooltip title="Caminhões" placement="top">
          <Tab value={2} icon={<LocalShippingIcon/>} aria-label="Caminhões"/>
        </Tooltip>
      </Tabs>
  );
};
