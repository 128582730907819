import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Autocomplete, TextField } from "@mui/material";
import { useRefs } from "../hooks";

interface ReferenceProps {
  selectedRef: SelectObject | null;
  setSelectedRef: Dispatch<SetStateAction<SelectObject | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const Reference = ({selectedRef, setSelectedRef, setLoading}: ReferenceProps) => {
  const { refs, isRefsLoading } = useRefs();

  useEffect(() => {
    setLoading(isRefsLoading);
    if (refs && refs.length > 0) {
      setSelectedRef(refs[0]);
    }
  }, [refs, isRefsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      id="ref"
      autoHighlight={true}
      options={refs ?? []}
      value={selectedRef}
      onChange={(_: React.SyntheticEvent, newValue: SelectObject | null) => {
        setSelectedRef(newValue);
      }}
      loading={isRefsLoading}
      loadingText="Carregando..."
      sx={{width: "200px"}}
      renderInput={(params) => <TextField {...params} label="Referência"/>}
    />
  );
};
