import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Autocomplete, TextField } from "@mui/material";
import { useBrands } from "../hooks";

interface BrandsProps {
  refId: number;
  vehicleType: number;
  selectedBrand: SelectObject | null;
  setSelectedBrand: Dispatch<SetStateAction<SelectObject | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const Brands = ({refId, vehicleType, selectedBrand, setSelectedBrand, loading, setLoading}: BrandsProps) => {
  const { brands, isBrandsLoading } = useBrands({refId, vehicleType});

  useEffect(() => {
    setLoading(isBrandsLoading);
  }, [isBrandsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="componentMainDiv">
      <div className="selectDiv">
        <Autocomplete
          id="brands"
          autoHighlight={true}
          options={brands || []}
          value={selectedBrand}
          onChange={(event: React.SyntheticEvent, newValue: SelectObject | null) => {
            setSelectedBrand(newValue);
          }}
          loading={loading}
          loadingText="Carregando..."
          sx={{width: "350px"}}
          renderInput={(params) => <TextField {...params} label="Marca"/>}
        />
      </div>
    </div>
  );
};
