import React, { useEffect, useMemo, useState } from 'react';
import './app.css';
import { Brands, Models, PricesChart, Reference, VehicleType } from "./components";
import { bikes, cars, trucks } from "./types";
import { Box, LinearProgress } from "@mui/material";

function App() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRef, setSelectedRef] = useState<SelectObject | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<SelectObject | null>(null);
  const [selectedModel, setSelectedModel] = useState<SelectObject | null>(null);

  const vehicleType = useMemo(() => {
    if (selectedTab === 0) {
      return cars;
    } else if (selectedTab === 1) {
      return bikes;
    } else {
      return trucks;
    }
  }, [selectedTab]);

  useEffect(() => {
    setSelectedBrand(null);
    setSelectedModel(null);
  }, [vehicleType, selectedRef]);

  useEffect(() => {
    setSelectedModel(null);
  }, [selectedBrand]);

  return (
    <div className="mainDiv">
      <div className="titleDiv">
        <h3>Desvaloriza</h3>

        <Reference
          selectedRef={selectedRef}
          setSelectedRef={setSelectedRef}
          setLoading={setLoading}
        />
      </div>

      {selectedRef && (
        <VehicleType
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}

      {selectedRef && (
        <Brands
          refId={selectedRef.id}
          vehicleType={vehicleType}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {selectedRef && selectedBrand && (
        <Models
          brandId={selectedBrand?.id}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {selectedRef && selectedBrand && selectedModel && (
        <PricesChart
          model={selectedModel}
          setLoading={setLoading}
        />
      )}

      {loading &&
        <Box sx={{ width: "100%", mt: "20px" }}>
          <LinearProgress color="info" />
        </Box>
      }

      <footer className="footer">
        <p>Desvaloriza.com</p>
      </footer>
    </div>
  );
}

export default App;
